export const mockOrganisation = (initialValue = {}) => {
	return {
		thirdPartyProvider: false,
		companyName: 'Test Organisation 1',
		sectorCode: 4,
		id: '85574b4b-b52e-44bd-bfd3-fed65095154d',
		zip: 'Test zip',
		address: 'Test Address',
		furtherAddress: 'Test Further Address',
		city: 'Test City',
		officialLanguage: 'de-DE',
		country: 'DE',
		mbbFleetId: '123',
		mod4FleetId: '14',
		verificationLevel: 1,
		clientId: 'organisation-id-service',
		legalConfirmationUserId: 'f20152a2-2cf8-4e18-bded-9f8b61658647',
		legalConfirmationDate: '2022-10-31T14:44:46Z',
		dpaConfirmationDate: '2022-10-31T14:44:46Z',
		vatId: 'DE123456789',
		dpaAccepted: true,
		fdpCustomersConsentDate: '2022-10-31T14:44:46Z',
		source: 'portal',
		fleetComId: '1234',
		orgAdmin: {
			firstName: 'first',
			email: 'first@mail.com',
			lastName: 'last',
			id: 'f20152a2-2cf8-4e18-bded-af8b12358647',
		},
		...initialValue,
	};
};

export const mockOrganisationVerification = (initialValue = {}) => {
	return {
		id: '5cc3ae75-9631-4cb2-9e55-47ed04d49f5a',
		organisationId: 'cf3aae7d-54bd-4e6e-a4e9-a9493fc97605',
		registrationData: 'Test registration data',
		managingDirector: true,
		powerOfAttorneyFileId: null,
		status: 'registered',
		userId: null,
		submissionDate: null,
		verificationOperatorUserId: null,
		verificationOperatorUpdateDate: null,
		explanation: null,
		hasAlternativeProof: null,
		proofDocs: [],
		claimStatus: 'unlock',
		claimUserId: null,
		claimUpdateDate: null,
		decisionDocs: [],
		comments: null,
		updatedAt: '2023-10-24T11:37:30+01:00',
		...initialValue,
	};
};

export const mockKeycloakInfo = (initialValue = {}) => {
	return {
		tokenParsed: {
			scope: {},
		},
		hasRealmRole: () => {},
		...initialValue,
	};
};

export const mockError = (initialValue = {}) => {
	return {
		id: '63735749-ef73-46df-8b52-0d3742d74409',
		status: '400',
		code: '4088',
		field: null,
		value: null,
		timestamp: '2022-11-28T14:03:19.606492Z',
		detail: 'Organisation is already third party provider.',
		multipleErrors: null,
		...initialValue,
	};
};

export const mockGetAllRolesOrgAdmin = () => {
	return {
		assignedGlobalRoles: [
			'organisation_admin',
			'organisation_user',
			'organisation_fleet_admin',
		],
		availableGlobalRoles: ['organisation_user_admin'],
		clientRoles: [
			{
				assignedRoles: [],
				availableRoles: ['enrollment_callback'],
				clientId: 'commercial-enrollment-service',
				clientName: 'Commercial Enrollment Service',
			},
			{
				assignedRoles: [],
				availableRoles: ['connect_pro_admin', 'connect_pro_operator'],
				clientId: 'connect-pro',
				clientName: 'Connect Pro',
			},
			{
				assignedRoles: [],
				availableRoles: ['fda_developer', 'fda_manager', 'fda_external_system'],
				clientId: 'fleet-data-api',
				clientName: 'Fleet Data Api',
			},
			{
				clientId: 'test-client',
				clientName: 'test client',
				availableRoles: [
					'test_client_role_min_1_max_2',
					'test_client_role_max_1',
					'test_client_role_min_1',
					'test_client_role',
				],
				assignedRoles: [
					'test_client_role_min_1_max_2',
					'test_client_role_max_1',
					'test_client_role_min_1',
					'test_client_role',
				],
			},
		],
	};
};

export const mockGetAllRolesOrgUser = () => {
	return {
		assignedGlobalRoles: ['organisation_user'],
		availableGlobalRoles: ['organisation_user_admin'],
		clientRoles: [
			{
				clientId: 'commercial-enrollment-service',
				clientName: 'Commercial Enrollment Service',
				availableRoles: ['enrollment_callback'],
				assignedRoles: ['enrollment_callback'],
			},
			{
				clientId: 'connect-pro',
				clientName: 'Connect Pro',
				availableRoles: ['connect_pro_admin', 'connect_pro_operator'],
				assignedRoles: [],
			},
			{
				clientId: 'fleet-data-api',
				clientName: 'Fleet Data Api',
				availableRoles: ['fda_developer', 'fda_manager', 'fda_external_system'],
				assignedRoles: [],
			},
			{
				clientId: 'test-client',
				clientName: 'test client',
				availableRoles: [
					'test_client_role_min_1_max_2',
					'test_client_role_max_1',
					'test_client_role_min_1',
					'test_client_role',
				],
				assignedRoles: [
					'test_client_role_min_1_max_2',
					'test_client_role_max_1',
					'test_client_role_min_1',
					'test_client_role',
				],
			},
		],
	};
};

export const mockClientRolesError = () => {
	return {
		errors: [
			{
				code: '4065',
				detail: 'Number of users in some roles violate min or max condition',
				field: null,
				id: 'd3018fa9-1f7d-4f0a-a7f1-0c1ddb6ebfc1',
				multipleErrors: [
					{
						clientId: 'test-client',
						roleName: 'test_client_role_min_1_max_2',
						errorEnumCode: 4054,
					},

					{
						clientId: 'test-client',
						roleName: 'test_client_role_min_1',
						errorEnumCode: 4054,
					},
				],

				timestamp: '2023-05-22T08:24:48.581423Z',
				value: null,
			},
		],
		timestamp: '2023-05-22T08:24:48.581369Z',
	};
};

export const mockMarkets = () => {
	return {
		markets: [
			{ countryCode: 'DE', contractLanguages: ['de-DE'] },
			{ countryCode: 'AT', contractLanguages: ['de-AT'] },
			{ countryCode: 'CZ', contractLanguages: ['cs-CZ'] },
			{ countryCode: 'FR', contractLanguages: ['fr-FR'] },
			{ countryCode: 'PL', contractLanguages: ['pl-PL'] },
		],
	};
};

export const mockUserInfo = () => {
	return {
		companyName: 'Test Org',
		orgId: 'c6582b65-5126-4a29-a664-46b3e0636a0c',
		supportPinStatus: 'active',
		parentComponentId: '',
		user: {
			id: 'testid',
			firstName: 'John',
			lastName: 'Doe',
			email: 'test1@test1.com',
			loginHistory: {
				currentLogin: '',
				failedLoginCount: 0,
				lastFailedLogin: '',
				lastLogin: '',
			},
			roles: ['admin'],
		},
	};
};

export const mockOrgTree = isOpen => {
	return {
		orgId: '1',
		companyName: 'Company 1',
		open: isOpen,
		children: [
			{
				orgId: '1.1',
				companyName: 'Company 1.1',
				open: isOpen,
			},
			{
				orgId: '1.2',
				companyName: 'Company 1.2',
				open: isOpen,
				children: [
					{ orgId: '1.2.1', companyName: 'Company 1.2.1' },
					{ orgId: '1.2.2', companyName: 'Company 1.2.2' },
					{
						orgId: '85574b4b-b52e-44bd-bfd3-fed65095154d',
						companyName: 'Company 1.2.3',
					},
				],
			},
		],
	};
};

export const mockMarketingConsentMarkets = () => ({
	marketingConsentMarkets: {
		DE: {
			enabled: true,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'Seat Deutschland GmbH',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		PL: {
			enabled: true,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		CZ: {
			enabled: true,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		FR: {
			enabled: true,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		AT: {
			enabled: false,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		GB: {
			enabled: false,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		IT: {
			enabled: false,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
		ES: {
			enabled: false,
			marketingConsentBrands: {
				VW: {
					brandName: 'Volkswagen (PC & CV)',
					legalEntity: 'Volkswagen AG',
				},
				AUDI: {
					brandName: 'Audi',
					legalEntity: 'Audi AG',
				},
				SEAT: {
					brandName: 'Seat/Cupra',
					legalEntity: 'SEAT, S.A.',
				},
				SKODA: {
					brandName: 'Skoda',
					legalEntity: 'Škoda Auto a.s.',
				},
				VWFS: {
					brandName: 'VWFS',
					legalEntity: 'Volkswagen Financial Services AG',
				},
				VWGIS: {
					brandName: 'GIS',
					legalEntity: 'Volkswagen Group Info Services AG',
				},
			},
		},
	},
});
