import constants from './constants.json';
import errorList from './errorList.json';
import adobeConstants from './adobeConstants.json';

export const getFormatedDateTime = (dateToFormat, locale, withTime = false) => {
	if (!dateToFormat) {
		return '';
	}

	const dateTimeOptions = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	};

	const {
		hour: hideHour,
		minute: hideMinute,
		...dateOptions
	} = dateTimeOptions;

	const date = new Date(Date.parse(dateToFormat));

	return new Intl.DateTimeFormat(
		[locale || 'de-DE', 'default'],
		withTime ? dateTimeOptions : dateOptions
	).format(date);
};

export const deepEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if (
			(areObjects && !deepEqual(val1, val2)) ||
			(!areObjects && val1 !== val2)
		) {
			return false;
		}
	}

	return true;
};

const isObject = object => {
	return object != null && typeof object === 'object';
};

export const isOperatorLogged = keycloakInfo =>
	keycloakInfo &&
	(keycloakInfo.hasRealmRole('organisation_verification_admin') ||
		keycloakInfo.hasRealmRole('administrator') ||
		keycloakInfo.hasRealmRole('customer_care_agent') ||
		keycloakInfo.hasRealmRole('gdpr_agent') ||
		keycloakInfo.hasRealmRole('sanction_list_operator'));

export const isUserLogged = keycloakInfo =>
	keycloakInfo && keycloakInfo.hasRealmRole('organisation_user');

const languageComparator = (a, b) => {
	const codeA = a.codePointAt();
	const codeB = b.codePointAt();
	const unicodeBreaker = 191;

	if (
		(codeA < unicodeBreaker && codeB < unicodeBreaker) ||
		(codeA > unicodeBreaker && codeB > unicodeBreaker)
	) {
		if (codeA < codeB) {
			return -1;
		}
		if (codeA > codeB) {
			return 1;
		}
		return 0;
	}
	if (
		(codeA < unicodeBreaker && codeB > unicodeBreaker) ||
		(codeA > unicodeBreaker && codeB < unicodeBreaker)
	) {
		if (codeA < codeB) {
			return 1;
		}
		if (codeA > codeB) {
			return -1;
		}
		return 0;
	}
};

export const sortedOptions = (options, renderOption) =>
	options
		.sort((a, b) => languageComparator(a.label, b.label))
		.map(renderOption);

export const upperLowerRegex = /(?=.*[\p{Lu}])(?=.*[\p{Ll}]).*/u;

export const base64ToArrayBuffer = base64 => {
	const binaryString = window.atob(base64);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);
	for (let i = 0; i < binaryLen; i++) {
		const ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
};

export const convertToBase64 = file => {
	return new Promise(resolve => {
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = () => {
			const baseURL = reader.result.split(',')[1];
			resolve(baseURL);
		};
	});
};

export const prettyRoleNameList = rolesList =>
	rolesList.sort().map((role, i) => {
		if (rolesList.length === i + 1) {
			return prettyRoleName(role);
		}
		return prettyRoleName(role) + ', ';
	});

export const displayClientsIds = clientsList =>
	clientsList.map((client, i) => {
		if (i !== 0) {
			return ' ' + client;
		}
		return client;
	});

export const prettyRoleName = role => {
	switch (role) {
		case 'organisation_admin':
			return constants.role.orgadmin;
		case 'organisation_user':
			return constants.role.user;
		case 'organisation_fleet_admin':
			return constants.role.fleetadmin;
		case 'organisation_user_admin':
			return constants.role.useradmin;
		default:
			return role;
	}
};

export const getMessages = (collection, setFunction) => {
	if (collection) {
		const errors = collection.reduce(
			(acc, { field, code }) => ({ ...acc, [field]: code }),
			{}
		);
		setFunction(errors);
	} else {
		console.log(
			'Error while accessing the BE. Make sure the BE is up and running'
		);
	}
};

export const getStatusTranslation = (messages, value) => {
	switch (value) {
		case 'sanction_list_rejected':
			return messages.status['rejected'];
		case 'sanction_list_pending':
		case 'verification_operator_approved':
			return messages.status['verified'];
		default:
			return messages.status[value];
	}
};

export const getSanctionStatus = (messages, sanctionStatus) => {
	switch (sanctionStatus) {
		case 'sanction_list_verification_pending':
		case 'sanction_list_info_reviewing':
			return {
				id: messages.status[sanctionStatus].id,
				color: 'orange',
			};
		case 'sanction_list_info_waiting':
		case 'sanction_list_vocs_pending':
		case 'sanction_list_vocs_resend_needed':
			return {
				id: messages.status[sanctionStatus].id,
				color: 'grey',
			};
		case 'sanction_list_approved':
			return {
				id: messages.status.sanction_list_approved.id,
				color: 'green',
			};
		default:
			return {
				id: messages.status.sanction_list_rejected.id,
				color: 'red',
			};
	}
};

//Upload validations
export const validateFileSize = (
	file,
	setFunction,
	message,
	specificFileSize
) => {
	const sizeToCompare = specificFileSize
		? specificFileSize
		: constants.maxFileSize;

	if (file.size > sizeToCompare) {
		console.error('file size is too large');
		setFunction(message);
		return true;
	}
};

export const checkForErrors = (
	data,
	setFunction,
	message,
	setLoading,
	state
) => {
	if (data?.errors) {
		setFunction(message);
		setLoading(state);
		return true;
	}
};

export const validateLimit = (docList, setFunction, message, limit) => {
	if (docList.length > limit) {
		setFunction(message);
		return true;
	}
};

export const validateFileType = (file, setFunction, message, specificFile) => {
	const split = file.name.split('.');
	const extension = split[split.length - 1];
	const extensionToCompare = extension?.toLowerCase();
	if (specificFile && extensionToCompare !== specificFile) {
		setFunction(message);
		return true;
	}
	if (
		extensionToCompare !== 'jpg' &&
		extensionToCompare !== 'jpeg' &&
		extensionToCompare !== 'png' &&
		extensionToCompare !== 'pdf' &&
		!specificFile
	) {
		setFunction(message);
		return true;
	}
};

export const findErrorMessage = code =>
	errorList.errors.find(element => element.code === code);

export const removeOpenModalClass = () => {
	document.body.classList.remove('modal-open');
};

export const addOpenModalClass = () => {
	document.body.classList.add('modal-open');
};

export const organisationIDFormatter = input => {
	const orgid = input
		.replace(/[^a-zA-Z0-9-,]/g, '')
		.replace(/[,]+/g, ',')
		.replace(/^,/, '');
	return orgid;
};

// Method for splitting strings from Imprint
export const getImprintText = (value, index, isHeader, slice) => {
	if (value) {
		const splittedText = value?.split('/', [index]).slice(slice).toString();
		if (isHeader && splittedText) {
			return splittedText?.split(':', [1]) + ':';
		} else {
			return splittedText?.split(':', [2]).slice(1);
		}
	}
	return '';
};

export const hashStr = value => {
	let hash = 0;

	if (!value?.length) return hash;

	for (let i = 0; i < value.length; i++) {
		const char = value.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash;
	}

	return hash;
};

export const getSectorOptions = (sectors, formatMessage) =>
	sectors.map(sector => {
		return {
			value: sector.code,
			label: formatMessage({
				id: `screen_registration_sector_${sector.code}`,
			}),
		};
	});

export const getSelectedValue = (sectorCode, formatMessage) => {
	if (sectorCode && sectorCode !== constants.sector.noSelection)
		return formatMessage({
			id: `screen_registration_sector_${sectorCode}`,
		});
	return '';
};

const getAdobeRuleByType = elemType => {
	const {
		pageEvent,
		linkEvent,
		errorEvent,
		buttonEvent,
		iconEvent,
		inputEvent,
		ulEvent,
	} = adobeConstants.rules;
	switch (elemType) {
		case 'page':
			return pageEvent;
		case 'link':
			return linkEvent;
		case 'icon':
			return iconEvent;
		case 'error':
			return errorEvent;
		case 'input':
			return inputEvent;
		case 'ul':
			return ulEvent;
		default:
			return buttonEvent;
	}
};

export const triggerEventToAnalytics = (
	elemId,
	elemType,
	dataLayerSection,
	tag
) => {
	window.dataLayer.page.pageInfo[dataLayerSection] = tag;

	const event = new CustomEvent(getAdobeRuleByType(elemType));
	const element = document.getElementById(elemId);

	if (element) {
		element.dispatchEvent(event);
	} else {
		console.error(`Unable to find element: ${element}`);
	}
};

export const userMgmtEnabledInMarket = (marketsJson, localeOrMarket) => {
	if (!marketsJson || !localeOrMarket) {
		return false;
	}
	let market = localeOrMarket;
	const isLocale = localeOrMarket.length > 2;
	if (isLocale) {
		market = new Intl.Locale(localeOrMarket).region;
	}
	const now = new Date();
	const releaseDate = new Date(marketsJson[market].releaseDate);
	return now >= releaseDate;
};

export const getDefaultLang = userLang => {
	if (!userLang) {
		return;
	}

	const newLocale = constants.uiLanguages.find(
		lang => lang.isoCode === userLang
	);
	return newLocale ? newLocale.isoCode : 'de-DE';
};

export const getBackToLoginLink = (params, clientId, locale, loginUrl) => {
	const redirect = encodeURIComponent(params.get('redirect_uri'));
	const tabId = encodeURIComponent(params.get('tab_id'));
	const execution = encodeURIComponent(params.get('execution'));
	let backToLoginUrl = `?client_id=${clientId}&ui_locales=${locale}`;

	if (redirect && redirect !== 'null' && loginUrl) {
		const stateParam = params.get('state')
			? `&state=${params.get('state')}`
			: '';
		const modeParam = params.get('response_mode')
			? `&response_mode=${params.get('response_mode')}`
			: '';
		const scopeParam = params.get('scope')
			? `&scope=${params.get('scope')}`
			: '';
		const nonceParam = params.get('nonce')
			? `&nonce=${params.get('nonce')}`
			: '';
		const backUrlParams = stateParam + modeParam + scopeParam + nonceParam;

		backToLoginUrl = `${loginUrl}/auth/realms/organisation-user-id/protocol/openid-connect/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirect}${backUrlParams}&ui_locales=${locale}`;
	} else {
		if (tabId && execution && execution !== 'null') {
			backToLoginUrl = `${loginUrl}/auth/realms/organisation-user-id/login-actions/authenticate?client_id=${clientId}&tab_id=${tabId}&execution=${execution}&kc_locale=${locale}`;
		} else if (tabId) {
			backToLoginUrl = `${loginUrl}/auth/realms/organisation-user-id/login-actions/authenticate?client_id=${clientId}&tab_id=${tabId}&kc_locale=${locale}`;
		}
	}

	window.dataLayer.page.pageInfo.referringURL = backToLoginUrl;

	return backToLoginUrl;
};

export const userHasRole = (listOfRoles, role) => listOfRoles.includes(role);
