import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import UnsupportedApp from './unsupported/UnsupportedApp';
import './styles/vwag/vwag.css';

if (process.env.REACT_APP_ENABLE_MSW === 'true') {
	const { worker } = require('./mocks/browser');
	worker.start();
}

const IsSupportedBrowser = function () {
	try {
		if (typeof Symbol == 'undefined') {
			return false;
		}
		/* eslint-disable */
		eval('class Foo {}');
		eval('var bar = (x) => x+1');
		/* eslint-enable */
	} catch {
		return false;
	}
	return true;
};

if (IsSupportedBrowser()) {
	const SupportedApp = lazy(() => import('./SupportedApp'));
	ReactDOM.render(
		<Suspense fallback={<></>}>
			<SupportedApp />
		</Suspense>,
		document.getElementById('root')
	);
} else {
	ReactDOM.render(<UnsupportedApp />, document.getElementById('root'));
}
