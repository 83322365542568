import React from 'react';

const UnsupportedNotification = () => {
	return (
		<div className="notification-wrapper">
			<div className="flex flex--direction-column content center card-size">
				<i className="icon i-notice mar-top-large pad-top-medium"></i>
				<h1 className="h5 text-dark mar-top-medium">
					The current browser is not supported.
				</h1>
				<p className="subtitle text-dark mar-top-large">
					Please use one of the following:
				</p>
				<p className="subtitle text-dark">
					Edge, Chrome, Mozilla Firefox or Safari
				</p>
			</div>
		</div>
	);
};

export default UnsupportedNotification;
