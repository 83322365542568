import { rest } from 'msw';
import { API_URL } from '../../../utils/testConstants';
import { mockOrganisation, mockOrgTree } from '../../../data/entities';

export const handlers = [
	rest.post(`${API_URL}/organisations/fdpconsent/confirm`, (req, res, ctx) => {
		return res(ctx.status(204));
	}),
	rest.get(`${API_URL}/organisations/1/tree`, async (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockOrgTree()));
	}),
	rest.get(`${API_URL}/organisations/1`, async (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockOrganisation()));
	}),
	rest.get(`${API_URL}/organisations/1.2`, async (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockOrganisation()));
	}),
];
