import React, { useState } from 'react';
import UnsupportedFooter from './UnsupportedFooter';
import UnsupportedImprint from './UnsupportedImprint';
import UnsupportedNotification from './UnsupportedNotification';
import './UnsupportedStyles.css';

const UnsupportedApp = () => {
	const [showImprint, setShowImprint] = useState(false);

	return (
		<>
			<div className="flex-container">
				{showImprint ? (
					<UnsupportedImprint setShowImprint={setShowImprint} />
				) : (
					<UnsupportedNotification />
				)}
			</div>
			<UnsupportedFooter setShowImprint={setShowImprint} />
		</>
	);
};

export default UnsupportedApp;
