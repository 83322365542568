import React from 'react';
import { getImprintText } from '../utils/helpers';

class UnsupportedImprint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			imprintInfo: [],
		};
	}

	getBoardNames(boardNames) {
		if (boardNames) {
			let boardList = boardNames?.split(', ');
			boardList = boardList.filter(
				name => name !== boardNames.footerChairmanBody
			);
			boardList.unshift(
				this.state.imprintInfo.footerChairmanBody +
					' (' +
					this.state.imprintInfo.footerChairmanHeader.split(':', [1]) +
					')'
			);
			return boardList.map((item, index) => (
				<p
					data-testid={`unsupported-imprint-board-name-${index + 1}`}
					key={item}
				>
					{item}
				</p>
			));
		}
	}

	componentDidMount() {
		fetch('/config.json')
			.then(res => res.json())
			.then(async json => {
				const response = await fetch(
					`${json.api.url}/comid/imprint?locale=en-GB`
				);
				if (!response.ok) {
					this.setState(this.props.setShowImprint(false));
					throw Error(response.status);
				}
				const imprintInfo = await response.json();
				this.setState({ imprintInfo });
			});
	}

	render() {
		return (
			<div className="card-wrapper">
				<div className="card pad-vertical-large pad-horizontal-medium">
					<div className="card__content">
						<h1
							className="h5"
							data-testid={'unsupported-imprint-content-title'}
						>
							Imprint
						</h1>
						<p
							className="mar-top-large"
							data-testid="unsupported-imprint-content-intro"
						>
							Volkswagen AG is a stock corporation under German law, with
							headquarters in Wolfsburg.
						</p>
						<p
							className="mar-top-large"
							data-testid="unsupported-imprint-chairman"
						>
							<span
								className="font-bold"
								data-testid="unsupported-imprint-chairman-label"
							>
								{this.state.imprintInfo.footerSupervisoryHeader + ' '}
							</span>
							{this.state.imprintInfo.footerSupervisoryBody}
						</p>
						<h2
							className="mar-top-large font-as-smaller font-bold text-dark"
							data-testid="unsupported-imprint-board-label"
						>
							{this.state.imprintInfo.footerBoardManagementHeader}
						</h2>
						{this.getBoardNames(
							this.state.imprintInfo.footerBoardManagementBody
						)}

						<div className="mar-top-large">
							<p data-testid="unsupported-imprint-address">
								<span data-testid="unsupported-imprint-address-label">
									<strong>
										{getImprintText(
											this.state.imprintInfo.footerPostalAddress,
											1,
											true
										)}
									</strong>
								</span>
								{getImprintText(this.state.imprintInfo.footerPostalAddress, 1)}
							</p>
							<p data-testid="unsupported-imprint-phone">
								<span data-testid="unsupported-imprint-phone-label">
									<strong>
										{getImprintText(
											this.state.imprintInfo.footerTelFax,
											1,
											true
										)}
									</strong>
								</span>
								{getImprintText(this.state.imprintInfo.footerTelFax, 1)}
							</p>

							<p data-testid="unsupported-imprint-fax">
								<span data-testid="unsupported-imprint-fax-label">
									<strong>
										{getImprintText(
											this.state.imprintInfo.footerTelFax,
											2,
											true,
											1
										)}
									</strong>
								</span>
								{getImprintText(
									this.state.imprintInfo.footerTelFax,
									2,
									false,
									1
								)}
							</p>
							<p data-testid="unsupported-imprint-email">
								<span data-testid="unsupported-imprint-email-label">
									<strong>
										{getImprintText(
											this.state.imprintInfo.footerCustomerCareEmail,
											1,
											true
										)}
									</strong>
								</span>
								<a href="mailto:support@onebusinessid.com">
									{getImprintText(
										this.state.imprintInfo.footerCustomerCareEmail,
										2
									)}
								</a>
							</p>
						</div>
						<div className="mar-top-large">
							<p data-testid="unsupported-imprint-identification-text">
								{this.state.imprintInfo.footerIdentification}
							</p>
							<p data-testid="unsupported-imprint-identification-taxId">
								{this.state.imprintInfo.footerTaxId}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UnsupportedImprint;
