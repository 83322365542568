import { rest } from 'msw';
import {
	API_URL,
	mockOrgAdmin,
	mockOrgUser,
} from '../../../utils/testConstants';
import {
	mockGetAllRolesOrgAdmin,
	mockGetAllRolesOrgUser,
	mockClientRolesError,
} from '../../../data/entities';

export const handlers = [
	rest.get(`${API_URL}/users/${mockOrgAdmin.id}/all-roles`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockGetAllRolesOrgAdmin()));
	}),
	rest.get(`${API_URL}/users/${mockOrgUser.id}/all-roles`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(mockGetAllRolesOrgUser()));
	}),
	rest.put(`${API_URL}/users/${mockOrgUser.id}/all-roles`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json({}));
	}),
	rest.put(`${API_URL}/users/${mockOrgAdmin.id}/all-roles`, (req, res, ctx) => {
		return res(ctx.status(400), ctx.json(mockClientRolesError()));
	}),
];
