export const API_URL = 'http://localhost:9090/comid';
export const ORG_ID_SUCCESS = '85574b4b-b52e-44bd-bfd3-fed65095154d';
export const ORG_ID_FAIL_1 = '85574b4b-b52e-44bd-bfd3-fed6509515aa';
export const ORG_ID_FAIL_2 = '85574b4b-b52e-44bd-bfd3-fed650951005';
export const SURVEY_ID = 'd6cbc817-7ecc-426c-bd78-6c407e6e644a';
export const ORG_ID_WRONG = '123456';
export const CLIENT_FLEET_DATA_API = 'fleet-data-api';
export const CLIENT_CONNECT_FLEET = 'connect-fleet';

export const mockOrgAdmin = {
	email: 'test@test.com',
	firstName: 'orgAdmin',
	id: '4f1f1e80-cc90-464d-b725-564ca1419ceb',
	lastName: 'asd',
	loginHistory: {
		currentLogin: '2023-05-16T12:23:25.28Z',
		lastLogin: '2023-05-15T06:54:45.408Z',
		lastFailedLogin: null,
		failedLoginCount: 0,
	},
	roles: ['Org. admin, ', 'Fleet admin, ', 'User'],
	rolesIdList: [
		'organisation_admin',
		'organisation_fleet_admin',
		'organisation_user',
	],
};

export const mockOrgUser = {
	email: 'test123@test.com',
	firstName: 'userTest',
	id: '34da545a-1fed-4ee6-9572-a7397bd4b2f2',
	lastName: '1',
	loginHistory: {
		currentLogin: null,
		lastLogin: null,
		lastFailedLogin: null,
		failedLoginCount: 0,
	},
	roles: ['User'],
	rolesIdList: ['organisation_user'],
};

export const mockOrgUserWrong = {
	email: '',
	firstName: 'userTest',
	id: '34da545a-1fed-4ee6-9572-a7397bd4b2f2',
	lastName: '1',
	loginHistory: {
		currentLogin: null,
		lastLogin: null,
		lastFailedLogin: null,
		failedLoginCount: 0,
	},
	roles: ['User'],
	rolesIdList: ['organisation_user'],
};

export const mockUserRolesData = {
	assignedGlobalRoles: ['organisation_user'],
	availableGlobalRoles: [
		{ name: 'organisation_user', open: false },
		{
			name: 'organisation_user_admin',
			open: false,
		},
	],
	clientRoles: [
		{
			assignedRoles: [],
			availableRoles: [{ name: 'enrollment_callback', open: false }],
			clientId: 'commercial-enrollment-service',
			clientName: 'Commercial Enrollment Service',
		},
		{
			assignedRoles: [],
			availableRoles: [
				{ name: 'connect_pro_admin', open: false },
				{ name: 'connect_pro_operator', open: false },
			],
			clientId: 'connect-pro',
			clientName: 'Connect Pro',
		},
		{
			assignedRoles: [],
			availableRoles: [
				{ name: 'fda_developer', open: false },
				{ name: 'fda_manager', open: false },
				{ name: 'fda_external_system', open: false },
			],
			clientId: 'fleet-data-api',
			clientName: 'Fleet Data Api',
		},
		{
			clientId: 'test-client',
			clientName: 'test client',
			availableRoles: [
				{ name: 'test_client_role_min_1_max_2', open: false },
				{ name: 'test_client_role_max_1', open: false },
				{ name: 'test_client_role_min_1', open: false },
				{ name: 'test_client_role', open: false },
			],
			assignedRoles: [],
		},
	],
};

export const mockGetEmptyRoles = {
	assignedGlobalRoles: [],
	availableGlobalRoles: [],
	clientRoles: [],
};
