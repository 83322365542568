import React from 'react';

const UnsupportedFooter = ({ setShowImprint }) => {
	return (
		<footer className="footer cidk-footer" data-testid="ss-footer-container">
			<div className="footer__links footer__links_unsupported pad-medium">
				<p className="cidk-footer-link" onClick={() => setShowImprint(true)}>
					Imprint
				</p>
				<p className="cidk-footer-label">{`Copyright © ${new Date().getFullYear()} Volkswagen AG`}</p>
			</div>
			<div className="footer__banner">
				<img src="Volkswagen-AG_Logo_2018.svg" alt="" />
			</div>
		</footer>
	);
};

export default UnsupportedFooter;
