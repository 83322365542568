import { handlers as adminHandlers } from './comid/admin/adminHandler';
import { handlers as organisationHandlers } from './comid/organisations/organisationsHandler';
import { handlers as surveyHandler } from './comid/surveyHandler';
import { handlers as resendUsersInvitatonHandler } from './comid/users/resendUsersInvitatonHandler';
import { handlers as allRolesHandler } from './comid/users/allRolesHandler';
import { handlers as usersHandler } from './comid/users/usersHandler';
import { handlers as deleteHandler } from './comid/organisations/deleteHandler';
import { handlers as cookieHandler } from './comid/users/cookieHandler';

export const handlers = [
	...adminHandlers,
	...organisationHandlers,
	...surveyHandler,
	...resendUsersInvitatonHandler,
	...allRolesHandler,
	...usersHandler,
	...deleteHandler,
	...cookieHandler,
];
