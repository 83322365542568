import { rest } from 'msw';
import {
	API_URL,
	ORG_ID_SUCCESS,
	ORG_ID_WRONG,
	ORG_ID_FAIL_1,
	ORG_ID_FAIL_2,
	CLIENT_FLEET_DATA_API,
	CLIENT_CONNECT_FLEET,
} from '../../../utils/testConstants';
import { mockError } from '../../../data/entities';

export const handlers = [
	rest.delete(`${API_URL}/organisations/${ORG_ID_SUCCESS}`, (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				total: 1,
				deleted: 1,
				unprocessed: 0,
				blocked: 0,
				blockedOrganisations: [],
				message:
					'1 organisations deleted. Unprocessed 0. Blocked 0. Total 1(Duplicates 0)',
			})
		);
	}),
	rest.delete(
		`${API_URL}/organisations/${ORG_ID_FAIL_1},${ORG_ID_FAIL_2}`,
		(req, res, ctx) => {
			return res(
				ctx.status(200),
				ctx.json({
					total: 2,
					deleted: 0,
					unprocessed: 0,
					blocked: 2,
					blockedOrganisations: [
						{
							orgId: ORG_ID_FAIL_1,
							clients: [CLIENT_FLEET_DATA_API, CLIENT_CONNECT_FLEET],
						},
						{
							orgId: ORG_ID_FAIL_2,
							clients: [CLIENT_FLEET_DATA_API],
						},
					],
					message:
						'"message": "0 organisations deleted. Unprocessed 2. Blocked 2. Total 2(Duplicates 0)"',
				})
			);
		}
	),
	rest.delete(`${API_URL}/organisations/${ORG_ID_WRONG}`, (req, res, ctx) => {
		return res(
			ctx.status(400),
			ctx.json({
				timestamp: '2022-11-28T14:03:19.605826Z',
				errors: [
					mockError({
						code: '4016',
						detail: 'Invalid request',
					}),
				],
			})
		);
	}),
];
