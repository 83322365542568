import { rest } from 'msw';
import { API_URL } from '../../../utils/testConstants';

export const handlers = [
	rest.post(`${API_URL}/users/email/invite`, async (req, res, ctx) => {
		return res(
			await req.json().then(data => {
				if (data.email) {
					return ctx.status(204);
				}
				return (
					ctx.status(400),
					ctx.json({
						timestamp: '2022-11-28T14:03:19.605826Z',
						errors: [],
					})
				);
			})
		);
	}),
];
