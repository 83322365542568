import { rest } from 'msw';
import { API_URL } from '../../../utils/testConstants';
import { mockError } from '../../../data/entities';

const email = 'test1@test1.com';
const emailWrong = 'test1@test123.com';

export const handlers = [
	rest.get(`${API_URL}/users/email/${email}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json({}));
	}),
	rest.get(`${API_URL}/users/email/${emailWrong}`, (req, res, ctx) => {
		return res(
			ctx.status(404),
			ctx.json({
				timestamp: '2023-06-01T13:32:44.334801Z',
				errors: [
					mockError({
						code: '4003',
						detail: 'User not found',
					}),
				],
			})
		);
	}),
];
