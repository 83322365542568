import { rest } from 'msw';
import {
	API_URL,
	ORG_ID_SUCCESS,
	ORG_ID_FAIL_1,
	ORG_ID_FAIL_2,
} from '../../../utils/testConstants';
import {
	mockError,
	mockOrganisation,
	mockOrganisationVerification,
} from '../../../data/entities';

export const handlers = [
	rest.patch(
		`${API_URL}/admin/organisations/${ORG_ID_SUCCESS}`,
		(req, res, ctx) => {
			return res(
				ctx.status(200),
				ctx.json({
					organisation: mockOrganisation({
						thirdPartyProvider: true,
						companyName: 'Great Company',
					}),
					organisationVerification: mockOrganisationVerification(),
				})
			);
		}
	),
	rest.patch(
		`${API_URL}/admin/organisations/${ORG_ID_FAIL_1}`,
		(req, res, ctx) => {
			return res(
				ctx.status(400),
				ctx.json({
					timestamp: '2022-11-28T14:03:19.605826Z',
					errors: [
						mockError({
							code: '4088',
							detail: 'Organisation is already third party provider.',
						}),
					],
				})
			);
		}
	),
	rest.patch(
		`${API_URL}/admin/organisations/${ORG_ID_FAIL_1}`,
		(req, res, ctx) => {
			return res(
				ctx.status(400),
				ctx.json({
					timestamp: '2022-11-28T14:03:19.605826Z',
					errors: [
						mockError({
							code: '4088',
							detail: 'Organisation is already third party provider.',
						}),
					],
				})
			);
		}
	),
	rest.patch(
		`${API_URL}/admin/organisations/${ORG_ID_FAIL_2}`,
		(req, res, ctx) => {
			return res(
				ctx.status(400),
				ctx.json({
					timestamp: '2022-11-28T14:03:19.605826Z',
					errors: [],
				})
			);
		}
	),
	rest.get(`${API_URL}/admin/organisations`, (req, res, ctx) => {
		const searchValue = req.url.searchParams.get('search');

		if (searchValue === 'test') {
			return res(
				ctx.status(200),
				ctx.json({
					organisationsData: [
						{
							organisation: mockOrganisation(),
							organisationVerification: mockOrganisationVerification(),
							organisationAdmin: mockOrganisation().orgAdmin,
						},
					],
				})
			);
		} else {
			return res(
				ctx.status(404),
				ctx.json({
					timestamp: '2022-11-28T14:03:19.605826Z',
					errors: [],
				})
			);
		}
	}),
];
